import { Notifier } from '@airbrake/browser';

document.addEventListener('DOMContentLoaded', () => {
  const isProduction = process.env.RAILS_ENV === 'production';
  const useAirbrakeOnEnv =
    process.env.RAILS_ENV !== 'development' && process.env.RAILS_ENV !== 'test';

  if (useAirbrakeOnEnv) {
    window.airbrake = new Notifier({
      projectId: process.env.AIRBRAKE_PROJECT_ID,
      projectKey: process.env.AIRBRAKE_PROJECT_KEY,
      instrumentation: {
        onerror: false, // disable automatic handling of window.onerror
        console: false, // prevent console logs automatically being sent with error
      },
    });

    window.airbrake.addFilter((notice) => {
      const filter = notice;
      filter.context.environment = process.env.RAILS_ENV;
      filter.context.version = process.env.APP_VERSION;
      return filter;
    });
  }

  // re-allow airbrake to handle certain types of window errors
  window.onerror = (message) => {
    // if message is not a string, this can lead to errors from
    // Airbrake itself when it tries to turn the data into a JSON string
    if (typeof message === 'string') {
      if (!isProduction) {
        console.warn(message);
      }

      if (useAirbrakeOnEnv) {
        airbrake.notify({
          error: { message },
          context: { component: 'window.onerror' },
        });
      }
    } else {
      // NB: The version of datatables we are using creates errors that are caught by window.onerror, but that should be handled by datatables instead. In newer versions of datatables this is fixed and it's not necessary to filter out datatable errors this way
      const isDatatablesError =
        typeof message === 'object' &&
        message &&
        message.namespace &&
        message.namespace === 'dt';

      if (!isDatatablesError) {
        if (!isProduction) {
          console.warn(message);
        }

        if (useAirbrakeOnEnv) {
          airbrake.notify({
            error: 'Window error',
            context: {
              component: 'window.onerror',
              messageType: typeof message,
            },
          });
        }
      }
    }
  };
});
